import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { transformFiltersToGqlFilters } from 'modules/analytics/components/AssetFilter/utils';
import { LoadingComponent } from 'modules/analytics/components/Dataviz/LineChart/common';
import type { Co2EmissionType } from 'modules/analytics/reports/carbonEmissions/gql';
import { useCo2EmissionStatistics } from 'modules/analytics/reports/carbonEmissions/sections/CarbonEmissionsAnalysis/cards/Co2Statistics';
import { Co2EvolutionDrawer } from 'modules/analytics/reports/carbonEmissions/sections/CarbonEmissionsAnalysis/cards/EvolutionDrawer';
import { useMemo, useState } from 'react';
import { type PeriodScale, getEndPeriodDate } from 'utils/time';
import { getLast7DaysPeriod } from '../utils/date';
import { useGetCarbonEmissionsData } from './getCarbonEmissionsData';
import { CarbonEmissionsComponent } from './view';

type CarbonEmissionsProps = {
  assetFilters: AssetFilterOptions;
  since: Date;
  until: Date;
};

const computeSelectedPeriod = (scale: PeriodScale, selectedDay?: string) => {
  if (!selectedDay) {
    return null;
  }
  const since = new Date(selectedDay);
  if (scale === 'day') {
    return {
      since,
      until: getEndPeriodDate(since, 'day'),
    };
  }
  return getLast7DaysPeriod();
};

export const CarbonEmissions = (props: CarbonEmissionsProps) => {
  const [selectedDataPoint, setSelectedDataPoint] =
    useState<Co2EmissionType | null>(null);
  const [granularityScale, setGranularityScale] = useState<PeriodScale>('day');

  const { assetFilters, since, until } = props;

  const assetFiltersGQL = useMemo(() => {
    return transformFiltersToGqlFilters(assetFilters);
  }, [assetFilters]);

  const { loading, data } = useCo2EmissionStatistics({
    assetFilters: assetFiltersGQL,
    since,
    until,
  });

  const { loading: isChartsLoading, data: chartsData } =
    useGetCarbonEmissionsData({
      assetFilters: assetFiltersGQL,
      since,
      until,
    });

  if (loading || isChartsLoading || !data) {
    return <LoadingComponent />;
  }

  return (
    <>
      <CarbonEmissionsComponent
        statisticData={data}
        data={chartsData}
        setSelectedDataPoint={setSelectedDataPoint}
        setGranularityScale={setGranularityScale}
      />
      <Co2EvolutionDrawer
        granularityScale={granularityScale}
        selectedPeriod={computeSelectedPeriod(
          granularityScale,
          selectedDataPoint?.date,
        )}
        onClose={() => {
          setSelectedDataPoint(null);
        }}
        assetFilters={assetFiltersGQL}
        open={!!selectedDataPoint}
        weight={selectedDataPoint?.weight}
        assetsCount={selectedDataPoint?.assetsCount}
        activeAssetsCount={selectedDataPoint?.activeAssetsCount}
      />
    </>
  );
};
