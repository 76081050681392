export const ArrowUpTrendIcon = ({
  color,
  backgroundColor,
  style,
}: {
  color: string;
  backgroundColor: string;
  style?: React.CSSProperties;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect x="1" y="1" width="14" height="14" rx="7" fill={backgroundColor} />
    <path
      d="M7.99988 11V5"
      stroke={color}
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M5.30713 7.69291L8.00004 5"
      stroke={color}
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M10.6928 7.69291L7.99988 5"
      stroke={color}
      stroke-width="1.4"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
);
