import type { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { transformFiltersToGqlFilters } from 'modules/analytics/components/AssetFilter/utils';
import { LoadingComponent } from 'modules/analytics/components/Dataviz/LineChart/common';
import type { DailyIdleRate } from 'modules/analytics/reports/idleRate/gql';
import { EvolutionDrawer } from 'modules/analytics/reports/idleRate/sections/IdleRateAnalysis/cards/EvolutionDrawer';
import { useMemo, useState } from 'react';
import { type PeriodScale, getEndPeriodDate } from 'utils/time';
import { getLast7DaysPeriod } from '../utils/date';
import { useGetIdleRateData } from './getIdleRateData';
import { IdleRateComponent } from './view';

type IdleRateProps = {
  assetFilters: AssetFilterOptions;
  since: Date;
  until: Date;
};
const computeSelectedPeriod = (scale: PeriodScale, selectedDay?: string) => {
  if (!selectedDay) {
    return null;
  }
  // for idle rate, selectedDay is the unix timestamp string
  const since = new Date(+selectedDay);
  if (scale === 'day') {
    return {
      since,
      until: getEndPeriodDate(since, 'day'),
    };
  }
  return getLast7DaysPeriod();
};

export const IdleRate = ({ assetFilters, since, until }: IdleRateProps) => {
  const [selectedDataPoint, setSelectedDataPoint] =
    useState<DailyIdleRate | null>(null);
  const [granularityScale, setGranularityScale] = useState<PeriodScale>('day');

  const assetFiltersGQL = useMemo(() => {
    return transformFiltersToGqlFilters(assetFilters);
  }, [assetFilters]);

  const { loading, idleRateData } = useGetIdleRateData({
    assetFilters: assetFiltersGQL,
    since,
    until,
  });

  if (loading || !idleRateData) {
    return <LoadingComponent />;
  }

  return (
    <>
      <IdleRateComponent
        data={idleRateData.data}
        average={idleRateData.average}
        previousPeriodAverage={idleRateData.previousPeriodAverage}
        setSelectedDataPoint={setSelectedDataPoint}
        setGranularityScale={setGranularityScale}
      />
      <EvolutionDrawer
        assetFilters={assetFiltersGQL}
        open={!!selectedDataPoint}
        onClose={() => setSelectedDataPoint(null)}
        averageIdleRate={selectedDataPoint?.value ?? null}
        granularityScale={granularityScale}
        selectedPeriod={computeSelectedPeriod(
          granularityScale,
          selectedDataPoint?.date,
        )}
        activeAssetsCount={selectedDataPoint?.activeAssetsCount}
        usedAssetsCount={selectedDataPoint?.assetsCount}
        trackingProps={{
          page: 'home',
          section: 'analytics',
          source: 'idleRate',
        }}
      />
    </>
  );
};
