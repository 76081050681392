import {
  LineChart,
  type LineChartData,
} from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValue } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { Card } from 'modules/analytics/components/common/layout/Card';
import type { UsageHoursByPeriod } from 'modules/analytics/reports/usageHours/gql';
import type { useUsageHoursStatistics } from 'modules/analytics/reports/usageHours/sections/UsageHoursAnalysis/cards/UsageHoursStatistics';
import { Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { PeriodScale } from 'utils/time';
import { AssetsCount } from '../common/AssetsCount';

type UsageHoursComponentProps = {
  statisticData: ReturnType<Awaited<typeof useUsageHoursStatistics>>['data'];
  averageUsageHoursPerActiveDays: number | null;
  previousAverageUsageHoursPerActiveDays: number | null;
  data: LineChartData[];
  setSelectedDataPoint: (period: UsageHoursByPeriod | null) => void;
  setGranularityScale: (scale: PeriodScale) => void;
};

export const UsageHoursComponent = ({
  statisticData,
  data,
  setSelectedDataPoint,
  setGranularityScale,
}: UsageHoursComponentProps) => {
  const { t } = useTranslation('newHome');
  const navigate = useNavigate();

  return (
    <Card
      title={t('analytics.kpi.usageHours.title')}
      actionElement={
        <>
          <Button
            onClick={() => {
              setGranularityScale('week');
              setSelectedDataPoint(
                statisticData
                  ? {
                      activeAssetsCount:
                        statisticData.activeAssetsCount.current || 0,
                      averageUsageHoursPerActiveDays:
                        statisticData.averageUsageHoursPerActiveDays.current ||
                        0,
                      // date is not important in that case
                      date: new Date(),
                      usedAssetsCount:
                        statisticData.usedAssetsCount.current || 0,
                      averageActiveDaysPerUsedAssets:
                        statisticData.averageActiveDaysPerUsedAssets.current ||
                        0,
                    }
                  : null,
              );
            }}
          >
            {t('analytics.kpi.common.viewAssets')}
          </Button>
          <Button onClick={() => navigate('/analytics/usage-hours')}>
            {t('analytics.kpi.common.analyze')}
          </Button>
        </>
      }
      disableImageExport
    >
      <SimpleValue
        value={statisticData?.averageUsageHoursPerActiveDays.current || null}
        unit={'h'}
        comparativeValue={
          statisticData?.averageUsageHoursPerActiveDays.previous || null
        }
        orientation="row"
        hideDescription
      />
      <AssetsCount>
        {t('analytics.kpi.common.assetsCount', {
          assetsCount: statisticData?.usedAssetsCount.current,
        })}
      </AssetsCount>
      <LineChart
        data={data}
        unit={'h'}
        tooltipV2
        periodScale="day"
        displayBars
        onItemClicked={(item: UsageHoursByPeriod) => {
          setGranularityScale('day');
          setSelectedDataPoint(item);
        }}
      />
    </Card>
  );
};
