import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import { AssetListDrawer } from 'modules/analytics/components/common/AssetListDrawer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { type PeriodScale, formatDateWithPeriodScale } from 'utils/time';
import type { Unit } from 'utils/unit';
import { AssetsListTable } from '../../../components/AssetsListTable';
import { useAssetListExport } from '../../../lib/exportAssetListHook';

type Co2EvolutionDrawerProps = {
  selectedPeriod: { since: Date; until: Date } | null;
  granularityScale: PeriodScale;
  open: boolean;
  assetFilters: AssetFilterGQL;
  onClose: () => void;
  weight?: {
    unit: string;
    value: number;
  };
  assetsCount?: number;
  activeAssetsCount?: number;
};

export const Co2EvolutionDrawer = ({
  selectedPeriod,
  granularityScale,
  open,
  assetFilters,
  onClose,
  weight,
  assetsCount,
  activeAssetsCount,
}: Co2EvolutionDrawerProps) => {
  const { t } = useTranslation('analytics');

  const drawerHeader = () => {
    if (!selectedPeriod) return null;
    return (
      <SimpleValueWithInfosCard
        title={formatDateWithPeriodScale(
          new Date(selectedPeriod.since),
          granularityScale,
        )}
        infos={t('reports.common.assetsCountOverTotal', {
          count: assetsCount,
          total: activeAssetsCount,
        })}
        unit={weight?.unit as Unit}
        value={weight?.value ?? null}
      />
    );
  };

  const { triggerAssetListExport, exportLoading } = useAssetListExport();
  const handleAssetListExport = useCallback(() => {
    if (!selectedPeriod) return;
    triggerAssetListExport(
      assetFilters,
      selectedPeriod.since,
      selectedPeriod.until,
      'daily',
    );
  }, [assetFilters, selectedPeriod, triggerAssetListExport]);

  const mainDrawerContent = () => {
    if (!selectedPeriod) return null;
    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={selectedPeriod.since}
        until={selectedPeriod.until}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  return (
    <AssetListDrawer
      open={!!open}
      title={t('reports.carbonEmissions.analysis.evolution.assetDrawerTitle')}
      header={drawerHeader()}
      mainContent={mainDrawerContent()}
      onClose={onClose}
      trackingProps={{
        page: 'carbonEmissions',
        section: 'analysis',
        source: 'evolutionChart',
      }}
    />
  );
};
